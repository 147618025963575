import React from "react";
import styled from "@emotion/styled";
import withingsLogo from "../images/logo-withings-black-cropped.png";
import fitbitLogo from "../images/fitbit-logo.png";
import garminLogo from "../images/garmin-logo.png";
import concept2Logo from "../images/concept2-logo.png";
import stravaLogo from "../images/strava_compatible.png";
import trainerRoadLogo from "../images/trainerroad_primary.png";
import pelotonLogo from "../images/peloton_logo.png";
import polarLogo from "../images/polarflow.svg";
// import mfpLogo from "../images/mfp-logo.svg";
import zwiftLogo from "../images/zwift.png";

const SupportedPlatforms = styled.table`
  margin: 0 auto;
  /* border-collapse: collapse; */
  border-spacing: 1rem 0;

  td,
  th {
    padding: 0.5rem;
  }

  td {
    border: 1px solid #999;
    font-family: serif;
    font-size: 0.75rem;
    border-top: 0;
  }

  tr:first-child {
    td {
      border-top: 1px solid #999;
    }
  }

  th {
    font-family: "Roboto", serif;
  }
`;

const WithingsLogo = styled.img`
  width: 100px;
`;

const PolarLogo = styled.img`
  width: 125px;
`;

// const MFPLogo = styled.img`
//   width: 125px;
// `;

const GarminLogo = styled.img`
  width: 100px;
  display: block;
  margin: 0 auto;
`;

const FitbitLogo = styled.img`
  width: 100px;
  display: block;
  margin: 0 auto;
`;

const StravaLogo = styled.img`
  width: 100px;
  display: block;
  margin: 0 auto;
`;

const TrainerRoadLogo = styled.img`
  width: 150px;
  display: block;
  margin: 0 auto;
`;

const Concept2Logo = styled.img`
  width: 150px;
  display: block;
  margin: 0 auto;
`;

const PelotonLogo = styled.img`
  width: 120px;
  display: block;
  margin: 0 auto;
`;

const ZwiftLogo = styled.img`
  width: 110px;
  display: block;
  margin: 0 auto;
`;

// const StyledH2 = styled.h1`
//   font-family: "Roboto", serif;
//   padding: 0;
//   margin: 0.5rem auto;
// `;

export default class CompatibilityTable extends React.Component {
  render() {
    return (
      <>
        {/* <StyledH2>SmartScaleSync</StyledH2> */}
        <SupportedPlatforms>
          <thead>
            <tr>
              <th>Supported Sources</th>
              <th>Supported Destinations</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3>Garmin</h3>
              </td>
              <td>
                <TrainerRoadLogo src={trainerRoadLogo} alt="TrainerRoad" />
              </td>
            </tr>
            <tr>
              <td rowSpan={"6"} style={{ border: "none" }} />
              <td>
                <StravaLogo src={stravaLogo} alt="Strava" />
              </td>
            </tr>
            <tr>
              <td>
                <FitbitLogo
                  src={fitbitLogo}
                  alt="Fitbit (if not set as source)"
                />
              </td>
            </tr>
            <tr>
              <td>
                <PolarLogo src={polarLogo} alt="Polar Flow" />
              </td>
            </tr>
            <tr>
              <td>
                <ZwiftLogo src={zwiftLogo} alt="Zwift (via Fitbit)" /> (via
                Fitbit)
              </td>
            </tr>
            <tr>
              <td>
                <Concept2Logo src={concept2Logo} alt="Concept2 Logbook" />
              </td>
            </tr>
            <tr>
              <td>
                <PelotonLogo src={pelotonLogo} alt="Peloton" />
              </td>
            </tr>
          </tbody>
        </SupportedPlatforms>
      </>
    );
  }
}
