import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { initAuth, isLoggedIn, handleSignup } from "../account/services/auth";
import { Link, navigate } from "gatsby";
import CompatibilityTable from "../components/compatibilityTable";

initAuth();

const StyledButton = styled.button`
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgb(255, 61, 48),
    rgb(255, 43, 97)
  );
  padding: 10px 50px;
  border-radius: 5px;
  border-style: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 1rem auto;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:focus {
    outline: 0;
  }
`;
const StyledLink = styled(Link)`
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgb(255, 61, 48),
    rgb(255, 43, 97)
  );
  padding: 10px 35px;
  border-radius: 5px;
  border-style: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 1rem auto;
  display: block;
  max-width: 90px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:focus {
    outline: 0;
  }
`;

const ListDiv = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
`;

const LinkP = styled(Link)`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }
`;
const StyledH3 = styled.h3`
  font-family: "Roboto", serif;
`;
const StyledH2 = styled.h2`
  font-family: "Roboto", serif;
  padding: 0;
  margin: 0.5rem auto;
`;

const StarDiv = styled.div`
  overflow: hidden;
  position: relative;
`;

const StarSpan = styled.span`
  /* position: relative; */
  overflow: hidden;
  display: inline;
  /* float: left; */
  color: rgb(255, 215, 0);
  font-size: 20px;
`;

const StyledA = styled.a`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }
`;

export default class Application extends React.Component {
  render() {
    return (
      <Layout>
        <StyledH2>SmartScaleSync Legacy Access</StyledH2>
        {isLoggedIn() && <StyledLink to="/account/">Get Started</StyledLink>}
        <ListDiv>
          <p>
            This site remains active for users that have Garmin set as a weight
            source.
          </p>
          <p>
            You are unable to change plans or renew your membership on the
            legacy site. Once your membership has expired your access will also
            expire.
          </p>
        </ListDiv>
        <span
          style={{ display: "block", margin: "1rem auto", color: "#8A8A8A" }}
        >
          SmartScaleSync is not affiliated with any of the fitness apps we
          support.
        </span>
        <CompatibilityTable />
        <p>
          Take a look at our <LinkP to="/about/">about page</LinkP> if you want
          learn more.
        </p>
      </Layout>
    );
  }
}
